import React from 'react';
import Layout from '../components/layout';
import HeadHelmet from '../components/head';
import { graphql } from 'gatsby';
import Header from '../components/pages/news/Header';
import ReactMarkdown from 'react-markdown';
import NewsCards from '../components/pages/news/newsCards';
import { StyledBackground } from '../components/ui-elements/backgroundImage';
import Pagination from '../components/pagination';

const CompanyNews = ({ data, pageContext, location }) => {
  const { strapiSsNewsPage } = data;
  const { allStrapiSsNews } = data;
  const { limit, numPages, numOfPosts, currentPage } = pageContext;

  return (
    <>
      <HeadHelmet data={strapiSsNewsPage.MetaData} />
      <Layout>
        <StyledBackground
          img={strapiSsNewsPage.hero_image_news_page.childImageSharp.fluid}
          title={strapiSsNewsPage.title}
          titleWidth="100%"
        />
        <Header
          title={strapiSsNewsPage.recent_news_title}
          content={strapiSsNewsPage.recent_news_content}
        />
        <div className={'SharedEl__textContent--center'}>
          <NewsCards queryData={allStrapiSsNews} hideViewAllButton location={location.pathname}/>
        </div>
        <div className={'SharedEl__textContent--center'}>
          <Pagination
            currentPage={currentPage}
            numPages={numPages}
            numOfPosts={numOfPosts}
            totalItemCount={limit}
          />
        </div>
        <div className={'SharedEl__textContent--center'}>
          <ReactMarkdown>{strapiSsNewsPage.content}</ReactMarkdown>
        </div>
      </Layout>
    </>
  );
};

export default CompanyNews;
export const pageQuery = graphql`
  query Posts($limit: Int!, $skip: Int!) {
    strapiSsNewsPage {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      hero_image_news_page {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      content
      recent_news_title
      recent_news_content
    }
    allStrapiSsNews(sort: { fields: [published], order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          slug
          published
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
