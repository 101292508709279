import React from 'react';
import styled from 'styled-components';

import theme from '../../../theme';

const HeaderContainer = styled.div`
  margin-bottom: 3rem;
  .title {
    margin-top: ${theme.marginXS};
    text-align: center;
    @media (min-width: ${theme.breakpoints.tabletLandscape}) {
      margin-top: 1.75rem;
    }
  }
  .subtitle {
    text-align: center;
  }

  @media (max-width: ${theme.breakpoints.tabletLandscape}) {
    margin-bottom: ${theme.marginM};
  }
`;

export const Subtitle2 = styled.p`
  font-size: 20px;
  font-family: Lato, sans-serif;
  line-height: 1.5;
  ${props => props.customCSS && props.customCSS}
`;

const Header = ({ title, content }) => (
  <HeaderContainer>
    <h2 className="title">{title}</h2>
    <Subtitle2 className="subtitle">{content}</Subtitle2>
  </HeaderContainer>
);

export default Header;
